import { Link } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { RegularHero } from '../components/RegularHero';
import { GetInTouch } from '../components/GetInTouch';
import { StaticImage } from 'gatsby-plugin-image';
import { MembershipPanel } from '../components/MembershipPanel';
import { TriplePanels } from '../components/Triple-Panels';
import { MemberText } from '../components/MemberText';
import { Seo } from '../components/Seo';

const specials = [
    {
        type: 'Level 1 Plan',
        price: '$299.99 /Yr',
        subPrice: '$24.99 / month',
        subButton: 'Save $360 Annually',
        benefits: [
            'Unlimited emergency exams',
            '(1) Comprehensive new patient exam',
            '(1) Annual exam',
            `(2) Annual cleanings`,
            `(2) Oral cancer screenings`,
            '(2) Fluoride treatments',
            'All necessary radiographs (as required/needed)',
            '15% savings on all dental treatments (including cosmetic makeovers)',
            'Exclusive member only events & gear',
        ],
    },
    {
        type: 'Family Plan',
        price: '$99 per Child / Yr',
        subButton: 'Save $500 Annually',
        benefits: [
            'Includes Level 1 membership plan in addition to existing parent’s membership',
            'Includes Level 1 membership plan in addition to existing parent’s membership',
            'Includes Level 1 membership plan in addition to existing parent’s membership',
        ],
    },
    {
        type: 'Level 2 Plan',
        price: '$499.99 /Yr',
        subPrice: '$41.66 / month',
        subButton: 'Save $600 Annually',
        benefits: [
            'Includes all services in Level 1 plan',
            '(4) Periodontal maintenance cleanings',
            'Gingival irrigation',
            `20% off initial SRP treatment (per quad)`,
            '15% savings on all dental treatments (including cosmetic makeovers)',
            'Exclusive member only events & gear',
        ],
    },
];

const Membership: Page = () => (
    <>
        <DefaultLayout>
            <Seo
                title="Membership Plans, FL | Smilecraft Dental Studio"
                description="Ask about our affordable dental membership plans today."
            />
            <RegularHero prevPage="home" currentPage="Membership Plan">
                <StaticImage
                    quality={100}
                    className="regular-hero-image"
                    src="../images/membership-hero.jpg"
                    placeholder="blurred"
                    alt="dental team drinking"
                />
            </RegularHero>

            <MembershipPanel />
            <TriplePanels specials={specials} />
            <MemberText />
        </DefaultLayout>
    </>
);

export default Membership;
