import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import rectangle from '../images/svgs/mask-slant.svg';
import girl from '../images/homepage.jpg';
import { css, cx } from '@emotion/css';
import { SpecialPanel } from '../components/Special-Panel';

const StyledTripePanels = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .panel {
        margin: 2em 0em;
    }
    @media ${device.tablet} {
        flex-direction: row;
        flex-wrap: wrap;
        width: 720px;
        justify-content: space-around;
        align-items: start;
    }
    @media ${device.laptop} {
        flex-direction: row;
        flex-wrap: wrap;
        width: 1180px;
        margin: 0 auto;
        justify-content: space-around;
        align-items: start;
    }
`;

type Special = {
    type: string;
    price: string;
    subPrice?: string;
    subButton: string;
    benefits: string[];
};

interface Props {
    specials: Special[];
}

export const TriplePanels: React.FC<Props> = ({ specials }) => {
    const levelOne = [];
    levelOne.push(specials[0]);
    const familyPlan = [];
    familyPlan.push(specials[1]);
    const levelTwo = [];
    levelTwo.push(specials[2]);
    return (
        <StyledTripePanels>
            <div className="panel">
                <SpecialPanel specials={levelOne} />
            </div>
            <div className="panel">
                <SpecialPanel specials={familyPlan} />
            </div>
            <div className="panel">
                <SpecialPanel specials={levelTwo} />
            </div>
        </StyledTripePanels>
    );
};
