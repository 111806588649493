import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import { SlantedPicture } from './SlantedPicture';

const StyledMembershipPanel = styled.div`
    padding: 2em 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3,
    p,
    h4 {
        text-align: center;
    }
    h3 {
        line-height: 44.8px;
        margin-bottom: 0.5em;
    }

    h4 {
        line-height: 44.8px;
        margin-top: 0.5em;
        font-size: 32px;
    }

    .box {
        h4 {
            padding-top: 0.25em;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #efefef;
        margin: 2em 0em;
        max-width: 415px;
        box-shadow: 0px 4px 120px rgba(89, 113, 105, 0.2);
        border-radius: 16px;
        padding: 2em;
    }
    .box-container {
        margin: 2em 0em;
    }
    @media ${device.tablet} {
        h3 {
            margin-top: 0.5em;
            font-size: 52px;
        }
        p {
            width: 80%;
        }
        .box {
            margin: 1em 0em;
            min-width: 404px;
        }
        .box-container {
            flex-wrap: wrap;
            justify-content: space-between;
            display: flex;
            width: 860px;
        }
    }
    @media ${device.laptopL} {
        h3 {
            margin-bottom: 1em;
            font-size: 62px;
        }
    }
`;

export const MembershipPanel: React.FC = () => {
    return (
        <StyledMembershipPanel>
            <h3 className="header">Join Our SmileCraft Membership</h3>
            <p>
                At SmileCraft, it pays to be a member of our dental family. Whether you have limited
                or no insurance you'll receive premium preventive care, discounts on dental
                treatments, and much more when you become a member.
            </p>
            <div className="box-container">
                <div className="box">
                    <StaticImage
                        src="../images/svgs/check-blue.svg"
                        alt="blue check"
                        placeholder="none"
                    />
                    <h4>15% Savings on all Dental Treatments</h4>
                </div>
                <div className="box">
                    <StaticImage
                        src="../images/svgs/check-blue.svg"
                        alt="blue check"
                        placeholder="none"
                    />
                    <h4>All Necessary Radiographs</h4>
                </div>
                <div className="box">
                    <StaticImage
                        src="../images/svgs/check-blue.svg"
                        alt="blue check"
                        placeholder="none"
                    />
                    <h4>Unlimited Emergency Exams</h4>
                </div>
                <div className="box">
                    <StaticImage
                        src="../images/svgs/check-blue.svg"
                        alt="blue check"
                        placeholder="none"
                    />
                    <h4>Exclusive Member Only Events</h4>
                </div>
            </div>
        </StyledMembershipPanel>
    );
};
