import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';

const StyledMemberText = styled.div`
padding:1em;
h5{
    font-size:28px;
    color:${colors.navy};
    font-weight:900;
    margin:1em 1em 1em 0em;
}
ul{
padding-left:1em;
}
@media ${device.tablet}{
    padding:2em 2em 0em 2em;
}
@media ${device.laptopL}{
   max-width:800px;
   margin:0 auto;
   position:relative;
   right:7em;
}`;

export const MemberText: React.FC = () => {
    return <StyledMemberText>
         <h4>Additional Member Benefits</h4>
            <ul>
           <li>NO yearly maximums</li>
           <li>NO deductibles</li>
           <li>NO claim forms</li>
           <li>NO pre-authorization requirements</li>
           <li>NO pre-existing conditions limitations</li>
           <li>NO waiting periods</li>
            </ul>
            <h5>Learn About Exclusions
(The Fine Print)</h5>
        <ul>
           <li>Refusal of x rays or recommend treatment renders ineligible for membership</li>
           <li>Only to be used at this SmileCraft Dental Studio</li>
           <li>Cannot be used in conjunction with dental insurance</li>
            </ul>
    </StyledMemberText>;
};
