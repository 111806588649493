import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import rectangle from '../images/svgs/mask-slant.svg';
import girl from '../images/homepage.jpg';
import { css, cx } from '@emotion/css';

type Special = {
    type: string;
    price: string;
    subPrice?: string;
    subButton: string;
    benefits: string[];
};

interface Props {
    specials: Special[];
}

const StyledSpecialPanel = styled.div`
    position: relative;
    border-radius: 16px 0px;
    background: #efefef;
    width: 332px;
    padding: 1.5em 1.5em 0.5em;
    z-index: 0;
    border: 3px solid ${colors.gold};
    box-shadow: 0px 16px 32px -8px rgba(78, 167, 219, 0.16);
    .sub-price {
        font-size: 12px;
        color: ${colors.blue};
        position: relative;
        bottom: 3em;
    }
    .logo-container {
        background: #efefef;
        padding: 0.5em;
        z-index: 0;
        border-radius: 16px 0;
        border: 3px solid ${colors.gold};
        position: absolute;
        top: -2em;
        z-index: 2;
    }
    .type {
        margin: 1em 0em 0em;
        font-weight: 900;
        font-size: 24px;
        color: ${colors.navy};
    }
    .list {
        li {
            margin: 1em 0em;
        }
        margin: 1.5em 0em 1em;
        padding-left: 1em;
    }
    .price {
        color: #1883e7;
        margin: 0em;
        font-weight: 900;
        font-size: 32px;
        border-bottom: 2px solid ${colors.blue};
        padding-bottom: 1em;
    }
    button {
        margin-top: 0.5em;
    }
    .sub-button {
        font-size: 14px;
        margin-top: 0.5em;
    }
    @media ${device.tablet} {
        .sub-price {
            font-size: 12px;
            color: ${colors.blue};
            position: relative;
            bottom: 3em;
        }
        .sub-button {
            margin-bottom: 0.25em;
        }
        .price {
            padding-bottom: 1em;
        }
        .type {
            margin: 0em 0em -0.5em;
        }
    }
`;

export const SpecialPanel: React.FC<Props> = ({ specials }) => {
    const { type, price, subButton, benefits, subPrice } = specials[0];
    return (
        <StyledSpecialPanel>
            <div className="logo-container">
                <StaticImage
                    className="logo"
                    src="../images/svgs/crest-logo.svg"
                    alt="smile craft c emblem"
                    placeholder="none"
                />
            </div>
            <h4 className="type">{type}</h4>
            <p className="price">{price}</p>
            {subPrice && <p className="sub-price">{subPrice}</p>}
            <ul className="list">
                {benefits.map(benefit => {
                    return <li className="item">{benefit}</li>;
                })}
            </ul>
            <a target="_blank" rel="noreferrer" href='https://www.flexbook.me/smilecraft/1'>
            <Button color="blue">Schedule With Us</Button>
            </a>
            <p style={{fontWeight:'700'}} className="sub-button">{subButton}</p>
        </StyledSpecialPanel>
    );
};
